// extracted by mini-css-extract-plugin
export var button = "sectionTextImageCards-module--button--b3b1f";
export var cafeFormIntro = "sectionTextImageCards-module--cafe-form-intro--0fde4";
export var card = "sectionTextImageCards-module--card--1aeed";
export var cardImg = "sectionTextImageCards-module--card-img--52294";
export var cardNonClickable = "sectionTextImageCards-module--card-non-clickable--49a35";
export var cardTitle = "sectionTextImageCards-module--card-title--eb675";
export var cardWrapper = "sectionTextImageCards-module--card-wrapper--c6b67";
export var description = "sectionTextImageCards-module--description--b0f70";
export var highlight = "sectionTextImageCards-module--highlight--1189b";
export var redButton = "sectionTextImageCards-module--red-button--1520d";
export var redTransparentButton = "sectionTextImageCards-module--red-transparent-button--bb6d3";
export var title = "sectionTextImageCards-module--title--c3eda";
export var transparentButton = "sectionTextImageCards-module--transparent-button--a4166";
export var wrapper = "sectionTextImageCards-module--wrapper--febc7";