import React from 'react'
import * as styles from './sectionTextImageCards.module.scss'
import Title from '../Shared/Title/Title'
import { RichText } from '../Shared/RichText/RichText'
import {GatsbyImage} from 'gatsby-plugin-image'

const SectionTextImageCards = ({data}) => {
  const{title, description, multipleCards} = data
  return (
    <div className={`conatiner container-lg ${styles.wrapper}`}>
      <div>
        <h2 className={styles.title}><Title title = {title}/></h2>
      </div>
      { description && 
        <div className={styles.description}>
        <RichText content={description} />
        </div>}
      <div className={styles.cardWrapper}>
        {multipleCards.map((cards:any, id:number) =>{
          const {image, title, youTubeUrl} = cards
          if(youTubeUrl){
            return(
              <a href={youTubeUrl} target = '_blank' aria-label = {`link opens in new tab`} rel="noopener noreferrer"
              title="link opens in new tab" className={styles.card} key = {id}>
                <div className={styles.cardImg}>
                  <GatsbyImage 
                    image = {image.gatsbyImageData}
                    alt = {`${title || image.title} image`}
                    />
                </div>
              </a>
            )
          }
          return(
            <div className={styles.cardNonClickable} key = {id}>
              <div className={styles.cardImg}>
                <GatsbyImage 
                  image = {image.gatsbyImageData}
                  alt = {`${title || image.title} image`}
                  />
              </div>
              {title && <div className={styles.cardTitle}> {title} </div>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SectionTextImageCards