import React from 'react'
import Title from '../Shared/Title/Title'
import * as styles from './treeCorpseTextImage.module.scss'
import { RichText } from '../Shared/RichText/RichText'
import { GatsbyImage } from 'gatsby-plugin-image'

const TreeCorpseTextImage = ({data}) => {
    const {backgroundImage, title, description, image} = data
  return (
    <div className='conatainer' style={{background:  backgroundImage
        ? `url(${backgroundImage.file?.url}) no-repeat center center / cover` : ""}}>
        <div className='container-lg'>
            <div className={styles.wrapper}>
                <div className={styles.textWrapper}>
                    <div className={styles.title}><Title title = {title} /></div>
                    <div className={styles.desc}><RichText content={description} /></div>
                </div>
                <div>
                    <GatsbyImage 
                        image={image.gatsbyImageData}
                        alt= {`${image.title || title} image`} />
                </div>
            </div>
        </div>
    </div>
  )
}

export default TreeCorpseTextImage