import React from 'react'
import { graphql } from 'gatsby'
import HeroComponent from '../components/HeroComponent/HeroComponent'
import ComponentContent from '../components/ComponentContent/ComponentContent'
import SectionGrid from '../components/SectionGrid/SectionGrid'
import AlternateSection from '../components/AlternateSection/AlternateSection'
import SectionTextImageCards from '../components/SectionTextImageCards/SectionTextImageCards'
import TreeCorpseTextImage from '../components/TreeCorpseTextImage/TreeCorpseTextImage'
import ComponentMedia from '../components/ComponentMedia/componentMedia'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import Breadcrum from '../components/Breadcrum/Breadcrum'

const Article = ({
  pageContext,
  data: {
    contentfulPageArticle:{
      slug,
      seoTitle,
      seoDescription,
      sections, 
      breadcrum,
      thumbnailTitle
    },
    allContentfulPageArticle: {
        nodes
    }
  }
}) => {
  const lang = useUrl()

  if(!sections){
    return <div></div>
  }
  
  return (
    <div id = 'article'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}
        />
      {breadcrum && <Breadcrum data = {breadcrum} title={thumbnailTitle}/>}
      {sections.map((section:any, index:number) =>{
        switch(section.__typename){
          case "ContentfulComponentHero":
            return <HeroComponent data = {section} key = {index}/>
          case "ContentfulComponentContent":
            return <ComponentContent data = {section} key = {index}/>
          case "ContentfulComponentAlternateSection":
            return <AlternateSection data={section} key={index} />
          case "ContentfulComponentImageCards":
            return <SectionTextImageCards data={section} key={index} />
          case "ContentfulComponentGridSection":
            return <SectionGrid data={section} key={index} />
          case "ContentfulSectionWithTextAndImage":
            return <TreeCorpseTextImage data={section} key={index} />
          case "ContentfulComponentContentMedia":
            return <ComponentMedia data={section} key={index} spacedVideo />
        }
      })}
    </div>
  )
}

export const ArticleQuery = graphql`
    query ArticleQuery( $id: String!, $contentful_id: String! ){
        contentfulPageArticle(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            breadcrum {
              title
              slug
            }
            thumbnailTitle
            sections {
                __typename
                ...componentHero
                ...contentfulComponentGridSection
                ...alternateSection
                ...sectionWithTextImage
                ...componentContent
                ...componentImageCards
                ...componentMedia
            }
        }    
        allContentfulPageArticle(
            filter: {
                contentful_id: { eq: $contentful_id },
                id: { ne: $id },
                slug: { ne: null }
            }
        ) {
            nodes {
                slug
                node_locale
            }
        }    
    }
  `
export default Article