// extracted by mini-css-extract-plugin
export var button = "treeCorpseTextImage-module--button--d7a4d";
export var cafeFormIntro = "treeCorpseTextImage-module--cafe-form-intro--8ba55";
export var desc = "treeCorpseTextImage-module--desc--4ce9f";
export var highlight = "treeCorpseTextImage-module--highlight--f78d5";
export var redButton = "treeCorpseTextImage-module--red-button--7f6ca";
export var redTransparentButton = "treeCorpseTextImage-module--red-transparent-button--92558";
export var textWrapper = "treeCorpseTextImage-module--text-wrapper--c6ebd";
export var title = "treeCorpseTextImage-module--title--e6479";
export var transparentButton = "treeCorpseTextImage-module--transparent-button--5d0ab";
export var wrapper = "treeCorpseTextImage-module--wrapper--a0804";